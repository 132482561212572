// Access Types
export const INTERNAL_USER = 'internal'
export const CARRIER_USER = 'carrier'

// Criteria Types
export const AUCTION_ELIGIBLE_LOADS = 'AUCTION_ELIGIBLE_LOADS'
export const LOADS_IN_AUCTION = 'LOADS_IN_AUCTION'
export const AUCTION_HISTORY = 'AUCTION_HISTORY'

// Move Types
export const MOVE_TYPE_ALL = 'ALL'
export const MOVE_TYPE_GM = 'GENERAL_MERCHANDISE'
export const MOVE_TYPE_FOOD = 'FOOD_ONLY'

// Area Types
export const AREA_FIRST_MILE = 'FIRST_MILE'
export const AREA_MIDDLE_MILE = 'MIDDLE_MILE'

export const AREA_EXPENSE = 'EXPENSE'

export const ALL_AREAS = 'ALL'

// First mile internal/external
export const VIEW_AVAILABLE_LOADS_CRITERIA = '1'
export const VIEW_AVAILABLE_LOADS_CRITERIA_GM = '109'
export const VIEW_AVAILABLE_LOADS_CRITERIA_FOOD = '112'

export const LOADS_IN_AUCTION_CRITERIA = '3'
export const LOADS_IN_AUCTION_CRITERIA_GM = '116'
export const LOADS_IN_AUCTION_CRITERIA_FOOD = '117'

export const AUCTION_HISTORY_CRITERIA = '6'
export const AUCTION_HISTORY_CRITERIA_GM = '118'
export const AUCTION_HISTORY_CRITERIA_FOOD = '119'

// Middle mile internal
export const VIEW_AVAILABLE_MIDDLE_MILE_LOADS = '801'
export const VIEW_AVAILABLE_MIDDLE_MILE_LOADS_GM = '809'
export const VIEW_AVAILABLE_MIDDLE_MILE_LOADS_FOOD = '812'

export const VIEW_MIDDLE_MILE_LOADS_IN_AUCTION = '803'
export const VIEW_MIDDLE_MILE_LOADS_IN_AUCTION_GM = '911'
export const VIEW_MIDDLE_MILE_LOADS_IN_AUCTION_FOOD = '912'

export const VIEW_MIDDLE_MILE_AUCTION_HISTORY = '806'
export const VIEW_MIDDLE_MILE_AUCTION_HISTORY_GM = '813'
export const VIEW_MIDDLE_MILE_AUCTION_HISTORY_FOOD = '814'

// Expense loads

export const VIEW_AVAILABLE_EXPENSE_LOADS = '501'
export const VIEW_ALL_EXPENSE_LOADS = '502'
export const VIEW_EXPENSE_IN_AUCTION = '503'
export const VIEW_EXPENSE_LOADS_WITHOUT_BIDS = '504'
export const VIEW_EXPENSE_AUCTION_HISTORY = '506'
export const DEFAULT_LOADS_IN_AUCTION_CRITERIA = '917'

// Middle mile external
export const VIEW_MIDDLE_MILE_CARRIER_LOADS_IN_AUCTION = '903'
export const VIEW_MIDDLE_MILE_CARRIER_LOADS_IN_AUCTION_GM = '909'
export const VIEW_MIDDLE_MILE_CARRIER_LOADS_IN_AUCTION_FOOD = '910'

export const VIEW_MIDDLE_MILE_CARRIER_AUCTION_HISTORY = '906'
export const VIEW_MIDDLE_MILE_CARRIER_AUCTION_HISTORY_GM = '913'
export const VIEW_MIDDLE_MILE_CARRIER_AUCTION_HISTORY_FOOD = '914'

//First Mile external
export const VIEW_FIRST_MILE_CARRIER_LOADS_IN_AUCTION = '103'
export const VIEW_FIRST_MILE_CARRIER_LOADS_IN_AUCTION_GM = '114'
export const VIEW_FIRST_MILE_CARRIER_LOADS_IN_AUCTION_FOOD = '115'

export const VIEW_FIRST_MILE_CARRIER_AUCTION_HISTORY = '106'
export const VIEW_FIRST_MILE_CARRIER_AUCTION_HISTORY_GM = '120'
export const VIEW_FIRST_MILE_CARRIER_AUCTION_HISTORY_FOOD = '121'
export const NOT_EXISTS = '-1'

export const LOAD_BOARD_CRITERIA_ID_MAP = {
  [AUCTION_ELIGIBLE_LOADS]: {
    [INTERNAL_USER]: {
      [AREA_FIRST_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_AVAILABLE_LOADS_CRITERIA,
        [MOVE_TYPE_GM]: VIEW_AVAILABLE_LOADS_CRITERIA_GM,
        [MOVE_TYPE_FOOD]: VIEW_AVAILABLE_LOADS_CRITERIA_FOOD,
      },
      [AREA_MIDDLE_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_AVAILABLE_MIDDLE_MILE_LOADS,
        [MOVE_TYPE_GM]: VIEW_AVAILABLE_MIDDLE_MILE_LOADS_GM,
        [MOVE_TYPE_FOOD]: VIEW_AVAILABLE_MIDDLE_MILE_LOADS_FOOD,
      },
      [AREA_EXPENSE]: {
        [MOVE_TYPE_ALL]: VIEW_AVAILABLE_EXPENSE_LOADS,
      },
    },
    [CARRIER_USER]: {
      [AREA_FIRST_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_AVAILABLE_LOADS_CRITERIA,
        [MOVE_TYPE_GM]: VIEW_AVAILABLE_LOADS_CRITERIA_GM,
        [MOVE_TYPE_FOOD]: VIEW_AVAILABLE_LOADS_CRITERIA_FOOD,
      },
      [AREA_MIDDLE_MILE]: {
        [MOVE_TYPE_ALL]: NOT_EXISTS,
        [MOVE_TYPE_GM]: NOT_EXISTS,
        [MOVE_TYPE_FOOD]: NOT_EXISTS,
      },
      [AREA_EXPENSE]: {
        [MOVE_TYPE_ALL]: VIEW_AVAILABLE_EXPENSE_LOADS,
      },
    },
  },
  [LOADS_IN_AUCTION]: {
    [INTERNAL_USER]: {
      [AREA_FIRST_MILE]: {
        [MOVE_TYPE_ALL]: LOADS_IN_AUCTION_CRITERIA,
        [MOVE_TYPE_GM]: LOADS_IN_AUCTION_CRITERIA_GM,
        [MOVE_TYPE_FOOD]: LOADS_IN_AUCTION_CRITERIA_FOOD,
      },
      [AREA_MIDDLE_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_MIDDLE_MILE_LOADS_IN_AUCTION,
        [MOVE_TYPE_GM]: VIEW_MIDDLE_MILE_LOADS_IN_AUCTION_GM,
        [MOVE_TYPE_FOOD]: VIEW_MIDDLE_MILE_LOADS_IN_AUCTION_FOOD,
      },
      [AREA_EXPENSE]: {
        [MOVE_TYPE_ALL]: VIEW_EXPENSE_IN_AUCTION,
      },
      [ALL_AREAS]: {
        [MOVE_TYPE_ALL]: DEFAULT_LOADS_IN_AUCTION_CRITERIA,
      },
    },
    [CARRIER_USER]: {
      [AREA_FIRST_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_FIRST_MILE_CARRIER_LOADS_IN_AUCTION,
        [MOVE_TYPE_GM]: VIEW_FIRST_MILE_CARRIER_LOADS_IN_AUCTION_GM,
        [MOVE_TYPE_FOOD]: VIEW_FIRST_MILE_CARRIER_LOADS_IN_AUCTION_FOOD,
      },
      [AREA_MIDDLE_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_MIDDLE_MILE_CARRIER_LOADS_IN_AUCTION,
        [MOVE_TYPE_GM]: VIEW_MIDDLE_MILE_CARRIER_LOADS_IN_AUCTION_GM,
        [MOVE_TYPE_FOOD]: VIEW_MIDDLE_MILE_CARRIER_LOADS_IN_AUCTION_FOOD,
      },
      [AREA_EXPENSE]: {
        [MOVE_TYPE_ALL]: VIEW_EXPENSE_IN_AUCTION,
      },
      [ALL_AREAS]: {
        [MOVE_TYPE_ALL]: DEFAULT_LOADS_IN_AUCTION_CRITERIA,
      },
    },
  },
  [AUCTION_HISTORY]: {
    [INTERNAL_USER]: {
      [AREA_FIRST_MILE]: {
        [MOVE_TYPE_ALL]: AUCTION_HISTORY_CRITERIA,
        [MOVE_TYPE_GM]: AUCTION_HISTORY_CRITERIA_GM,
        [MOVE_TYPE_FOOD]: AUCTION_HISTORY_CRITERIA_FOOD,
      },
      [AREA_MIDDLE_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_MIDDLE_MILE_AUCTION_HISTORY,
        [MOVE_TYPE_GM]: VIEW_MIDDLE_MILE_AUCTION_HISTORY_GM,
        [MOVE_TYPE_FOOD]: VIEW_MIDDLE_MILE_AUCTION_HISTORY_FOOD,
      },
      [AREA_EXPENSE]: {
        [MOVE_TYPE_ALL]: VIEW_EXPENSE_AUCTION_HISTORY,
      },
    },
    [CARRIER_USER]: {
      [AREA_FIRST_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_FIRST_MILE_CARRIER_AUCTION_HISTORY,
        [MOVE_TYPE_GM]: VIEW_FIRST_MILE_CARRIER_AUCTION_HISTORY_GM,
        [MOVE_TYPE_FOOD]: VIEW_FIRST_MILE_CARRIER_AUCTION_HISTORY_FOOD,
      },
      [AREA_MIDDLE_MILE]: {
        [MOVE_TYPE_ALL]: VIEW_MIDDLE_MILE_CARRIER_AUCTION_HISTORY,
        [MOVE_TYPE_GM]: VIEW_MIDDLE_MILE_CARRIER_AUCTION_HISTORY_GM,
        [MOVE_TYPE_FOOD]: VIEW_MIDDLE_MILE_CARRIER_AUCTION_HISTORY_FOOD,
      },
      [AREA_EXPENSE]: {
        [MOVE_TYPE_ALL]: VIEW_EXPENSE_AUCTION_HISTORY,
      },
    },
  },
}

export const AUCTION_ELIGIBLE_CATEGORIES_CRITERIA = 40
