import {
  AREA_FIRST_MILE,
  AREA_MIDDLE_MILE,
  AREA_EXPENSE,
  MOVE_TYPE_GM,
  MOVE_TYPE_FOOD,
} from './CriteriaIds'

const DEFAULT_SORT_COLUMN = 'load_id'

export const DEFAULT_LOAD_BOARD_FILTERS = {
  area: '',
  move_type: '',
  status_list: [],
  load_id_list: [],
  trip_id_list: [],
  auction_from_date: '',
  auction_to_date: '',
}

export const DEFAULT_LOAD_SEARCH_FILTERS = {
  page: 1,
  per_page: 20,
  sort_by: DEFAULT_SORT_COLUMN,
  isDescendingSort: true,
  selected_filters: [],
  ...DEFAULT_LOAD_BOARD_FILTERS,
}

export const LOAD_STATUS_LIST = [
  'TENDERED',
  'TENDER_ACCEPTED',
  'TENDER_REJECTED',
  'TENDER_CANCELED',
  'IN_TRANSIT',
  'COMPLETED',
  'CANCELED',
  'AUCTION',
  'AUCTION_COMPLETED',
  'RATING_ERROR',
  'NO_WINNERS',
]

export const AREA_LIST = [AREA_FIRST_MILE, AREA_MIDDLE_MILE, AREA_EXPENSE]

export const MOVE_TYPE_LIST = [MOVE_TYPE_GM, MOVE_TYPE_FOOD]
