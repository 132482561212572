import { NovaFilterDrawer } from '@dlm/common'

import { startCase } from '../../common/util/stringUtil'

import {
  AREA_LIST,
  DEFAULT_LOAD_BOARD_FILTERS,
  LOAD_STATUS_LIST,
  MOVE_TYPE_LIST,
} from './constants/loadBoardConstants'
import { INTERNAL_USER, LOADS_IN_AUCTION } from './constants/CriteriaIds'

const AREA_OPTIONS = AREA_LIST.map((status) => ({
  value: status,
  label: startCase(status),
}))

const MOVE_TYPE_OPTIONS = MOVE_TYPE_LIST.map((status) => ({
  value: status,
  label: startCase(status),
}))

const LOAD_STATUS_OPTIONS = LOAD_STATUS_LIST.map((status) => ({
  value: status,
  label: startCase(status),
}))

const SearchFilterDrawer = ({
  onChange,
  isVisible,
  onRequestClose,
  selectedView,
  userType,
}) => {
  const handleChange = (values) => {
    // Needed to strip out object metadata added by hook form for tests to pass
    onChange(Object.assign({}, values))
    onRequestClose()
  }

  const filterMeta = {
    1: [
      {
        type: 'radio',
        name: 'area',
        label: 'Area',
        rules:
          selectedView === LOADS_IN_AUCTION
            ? { required: '' }
            : { required: 'Area is required' },
        options: AREA_OPTIONS,
        xs: 6,
      },
      {
        type: 'radio',
        name: 'move_type',
        label: 'Move Type',
        options: MOVE_TYPE_OPTIONS,
        xs: 6,
      },
    ],
    3: [
      {
        type: 'multi-text',
        name: 'load_id_list',
        label: 'Load IDs',
        ariaLabel: 'Load ID List',
        placeholder: 'Comma separated list of Load IDs',
        xs: 12,
      },
    ],
    4: [
      {
        type: 'multi-text',
        name: 'trip_id_list',
        label: 'Trip IDs',
        ariaLabel: 'Trip ID List',
        placeholder: 'Comma separated list of Trip IDs',
        xs: 12,
      },
    ],
    5: [
      {
        type: 'date',
        name: 'auction_from_date',
        label: 'Auction Start From Date',
        xs: 6,
      },
      {
        type: 'date',
        name: 'auction_to_date',
        label: 'Auction Start To Date',
        xs: 6,
      },
    ],
  }

  // Only display load status filter for internal users for loads not already in auction
  if (userType === INTERNAL_USER && selectedView !== LOADS_IN_AUCTION) {
    filterMeta[2] = [
      {
        type: 'checkboxes',
        name: 'status_list',
        label: 'Load Status',
        options: LOAD_STATUS_OPTIONS,
        xs: 4,
      },
    ]
  }

  const filterActionsMeta = {
    1: [
      {
        type: 'reset',
        xs: 12,
      },
    ],
    2: [
      {
        type: 'submit',
        xs: 12,
      },
    ],
  }
  return (
    <NovaFilterDrawer
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      headingText="Load Search Filters"
      location="right"
      filterMeta={filterMeta}
      filterActionsMeta={filterActionsMeta}
      defaultValues={DEFAULT_LOAD_BOARD_FILTERS}
      onSubmit={handleChange}
    />
  )
}

export default SearchFilterDrawer
