import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Countdown from 'react-countdown'
import { chain, isEmpty, map } from 'lodash'

import {
  Button,
  Chip,
  Grid,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelCircleIcon,
  DownloadIcon,
  RefreshIcon,
  SaveIcon,
} from '@enterprise-ui/icons'

import { FormSelect, FormTextField, NovaTable } from '@dlm/common'

import useUser from '../../../common/hooks/useUser'
import useRules from '../../../common/hooks/useRules'

import StopsModal from './StopsModal'
import BidsModal from './BidsModal'
import HazmatControl from './HazmatControl'
import StartAuctionButton from './StartAuctionButton'
import EndAuctionButton from './EndAuctionButton'

import bidService from '../services/bidService'

import { startCase } from '../../../common/util/stringUtil'
import { formatDate } from '../../../common/util/dateUtil'
import {
  getLoadStatusColor,
  separateLoadAndTripIDs,
} from '../util/loadBoardUtil'

import {
  AUCTION_HISTORY,
  AUCTION_ELIGIBLE_LOADS,
  LOADS_IN_AUCTION,
} from '../constants/CriteriaIds'
import {
  DEFAULT_COLUMN,
  LOAD_BOARD_COLUMNS,
} from '../constants/loadBoardColumns'
import useBusinessPartner from '../../../common/hooks/useBusinessPartner'

const mapDefaultValues = (loads, isCarrier, carriers) => {
  const defaultValues = {}
  loads.forEach((load) => {
    if (isCarrier && carriers) {
      const auctionEligibleSCACs = load.auction_eligible_scacs ?? []
      const activeBid = chain(load.bids)
        .filter((bid) => auctionEligibleSCACs.includes(bid.scac))
        .filter((bid) => bid.active)
        .first()
        .value()

      defaultValues[`scac-${load.load_id}`] = activeBid
        ? activeBid.scac
        : auctionEligibleSCACs[0]
      defaultValues[`bid_amount-${load.load_id}`] = activeBid
        ? activeBid.bid_amount
        : ''
    }
  })
  return defaultValues
}

const mapLoads = (
  loads,
  view,
  onUpdateBid,
  onRefresh,
  formContext,
  user,
  carriers,
  auctionRules,
) => {
  const mappedLoads = []

  const minBid = chain(auctionRules)
    .find((rule) => rule.rule === 'min_bid')
    .get('rule_values.value_list[0]')
    .value()

  const maxBid = chain(auctionRules)
    .find((rule) => rule.rule === 'max_bid')
    .get('rule_values.value_list[0]')
    .value()

  loads?.forEach((load) => {
    const vendorName = load?.load_details?.origin_location_name ?? ''
    const vendorId = load?.origin_location_id ?? ''
    const originAddress = load?.origin_address ?? ''

    const scac = load.current_scac || load.scac || ''

    const id = load.trip_id !== 0 ? load.trip_id : load.load_id

    const auctionEnd = load.auction_end_dt

    /*
    Trips have more than one detail.
    If it is a trip, it will have multiple details
    for each load with their own weight, volume, and
    mileage.
    */
    const weight = load?.details?.reduce(
      (acc, detail) => acc + (detail.load_weight ?? 0),
      0,
    )
    const volume = load?.details?.reduce(
      (acc, detail) => acc + (detail.load_volume ?? 0),
      0,
    )
    const total_mileage = load?.details?.reduce(
      (acc, detail) => acc + (detail.round_trip_mileage ?? 0),
      0,
    )

    mappedLoads.push({
      // Base columns
      id: id,
      'load_id/trip_id': id,
      move_type: startCase(load.move_type ?? load.subcategory),

      origin: `${vendorId} ${vendorName} - ${originAddress}`,
      destination: `${load.destination_location_id} - ${load.destination_address}`,

      stops: {
        cellValue: load?.load_stop_list?.length,
        cellDisplay:
          load?.load_stop_list?.length > 0 ? (
            <StopsModal load_stop_list={load?.load_stop_list} load={load} />
          ) : (
            <p>{load?.load_stop_list?.length}</p>
          ),
      },

      expected_pickup: formatDate(load.load_start_date),
      expected_delivery: formatDate(load.load_end_date),

      delivery_type: startCase(load.delivery_type),

      primary_scac_rate: `${load.primary_scac || ''} \n $${load.primary_rate}`,
      current_scac_rate: `${scac} \n $${load.current_rate}`,

      status: {
        cellValue: load.status,
        cellDisplay: load.status && (
          <Chip size="dense" color={getLoadStatusColor(load.status)}>
            {startCase(load.status)}
          </Chip>
        ),
      },

      // Available loads
      commodity: startCase(load.commodity) ?? 'N/A',
      service_code: load.service_code,

      weight: weight,
      volume: volume,
      total_mileage: total_mileage,

      power_only_move: load.is_power_only ? 'Yes' : 'No',
      hazmat: {
        cellValue: '',
        cellDisplay: (
          <HazmatControl load={load} view={view} onRefresh={onRefresh} />
        ),
      },

      // Loads in Auction
      bid_amount: {
        cellValue: '',
        cellDisplay: carriers && (
          <FormTextField
            name={`bid_amount-${load.load_id}`}
            aria-label={`Bid Amount - ${load.load_id}`}
            placeholder="Bid Amount"
            type="number"
            formContext={formContext}
            rules={{
              min: {
                value: minBid,
                message: `Minimum: ${minBid}`,
              },
              max: {
                value: maxBid,
                message: `Maximum: ${maxBid}`,
              },
            }}
          />
        ),
      },

      bidding_scac: {
        cellValue: '',
        cellDisplay: carriers && (
          <FormSelect
            name={`scac-${load.load_id}`}
            aria-label={`Bidding SCAC - ${load.load_id}`}
            formContext={formContext}
            placeholder="Select SCAC"
            options={map(load.auction_eligible_scacs, (scac) => ({
              value: scac,
              label: scac,
            }))}
          />
        ),
      },

      time_remaining: {
        cellValue: auctionEnd,
        cellDisplay: (
          <>
            {auctionEnd && Date.parse(auctionEnd) > Date.now() ? (
              <Countdown date={auctionEnd} value={auctionEnd} />
            ) : (
              auctionEnd
            )}
          </>
        ),
      },

      total_bids: {
        cellValue: load?.bids?.length,
        cellDisplay:
          load?.bids?.length > 0 ? (
            <BidsModal
              load={load}
              isCarrier={user?.access?.isCarrier}
              isAuctionEnded={view === AUCTION_HISTORY}
              onUpdateBid={onUpdateBid}
            />
          ) : (
            <p>{load?.bids?.length}</p>
          ),
      },

      // Auction history
      auction_start: formatDate(load.auction_start_dt),
      auction_end: formatDate(load.auction_end_dt),
    })
  })

  return mappedLoads
}

const LoadBoardTable = ({
  loads,
  loadCount,
  pageNum,
  pageSize,
  onExport,
  onUpdateBid,
  selectedView,
  userType,
  onRefresh,
  onPaginationChange,
  onSortChange,
  onRowSelect,
  selectedRows,
  ...restProps
}) => {
  const user = useUser()
  const {
    access: { isAdmin, isAuction, isCarrier },
  } = user

  const { carriers } = useBusinessPartner()
  const rules = useRules()
  const auctionRules = rules.filter(
    (rule) => rule.rule_type === 'AUCTION' && rule.active_flag === true,
  )

  const makeToast = ToastProvider.useToaster()

  const formContext = useForm({
    mode: 'onTouched',
    defaultValues: mapDefaultValues(loads, isCarrier, carriers),
  })

  const {
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    reset,
  } = formContext

  useEffect(() => {
    // reset the form values when loads are updated
    reset(mapDefaultValues(loads, isCarrier, carriers))
  }, [loads, reset, isCarrier, carriers])

  const submitBids = (values, dirtyFields) => {
    const rowsWithNewBids = Object.keys(dirtyFields).map((field) =>
      chain(field).split('-').last().value(),
    )

    const [loadsWithNewBids, tripsWithNewBids] = separateLoadAndTripIDs(
      loads,
      rowsWithNewBids,
    )

    const newBids = rowsWithNewBids.map((rowId) => ({
      load_id: loadsWithNewBids.includes(rowId) ? rowId : null,
      trip_id: tripsWithNewBids.includes(rowId) ? rowId : null,
      bid_amount: values[`bid_amount-${rowId}`],
      scac: values[`scac-${rowId}`],
    }))

    bidService
      .submitBids(newBids)
      .then(() => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: `Bids submitted: ${rowsWithNewBids.length}`,
        })
        onRefresh()
      })
      .catch(() => {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: 'Error submitting bids. Please try again',
        })
      })
  }

  const rowData = useMemo(
    () =>
      mapLoads(
        loads,
        selectedView,
        onUpdateBid,
        onRefresh,
        formContext,
        user,
        carriers,
        auctionRules,
      ),
    [
      loads,
      selectedView,
      onUpdateBid,
      onRefresh,
      formContext,
      user,
      carriers,
      auctionRules,
    ],
  )

  const columnDefs = LOAD_BOARD_COLUMNS[userType][selectedView]

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <NovaTable
        name={`${startCase(selectedView)}`}
        aria-label={`${startCase(selectedView)} Table`}
        showHeader
        columnDefs={columnDefs}
        defaultColumnDef={DEFAULT_COLUMN}
        rowData={rowData}
        rowCount={loadCount}
        pageNum={pageNum}
        pageSize={pageSize}
        onPaginationChange={onPaginationChange}
        onSortChange={(direction, field) => {
          const column = columnDefs.find((column) => column.field === field)
          onSortChange(direction, column?.sortBy)
        }}
        tableActions={
          <Grid.Container
            align="center"
            justify="flex-end"
            spacing="dense"
            noWrap
          >
            {selectedView === LOADS_IN_AUCTION && isCarrier && (
              <>
                <Grid.Item>
                  <Tooltip content="Save Bids" location="bottom">
                    <Button
                      iconOnly
                      aria-label="Save Bids"
                      className="table-action-button"
                      disabled={!isDirty || !isEmpty(errors)}
                      onClick={handleSubmit((values) =>
                        submitBids(values, dirtyFields),
                      )}
                    >
                      <EnterpriseIcon icon={SaveIcon} size="lg" />
                    </Button>
                  </Tooltip>
                </Grid.Item>
                <Grid.Item>
                  <Tooltip content="Reset" location="bottom">
                    <Button
                      iconOnly
                      aria-label="Reset Changes"
                      data-testid="reset-btn"
                      className="table-action-button"
                      disabled={!isDirty}
                      onClick={() => reset(mapDefaultValues(loads))}
                    >
                      <EnterpriseIcon icon={CancelCircleIcon} />
                    </Button>
                  </Tooltip>
                </Grid.Item>
              </>
            )}
            <Grid.Item>
              <Tooltip content="Refresh Loads" location="bottom">
                <Button
                  iconOnly
                  aria-label="Refresh Loads"
                  className="table-action-button"
                  data-testid="load-refresh-btn"
                  onClick={onRefresh}
                >
                  <EnterpriseIcon icon={RefreshIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
            <Grid.Item>
              <Tooltip content="Export CSV" location="bottom">
                <Button
                  iconOnly
                  aria-label="Export CSV"
                  data-testid="load-export-btn"
                  className="table-action-button"
                  onClick={() => onExport('LOAD_BOARD')}
                >
                  <EnterpriseIcon icon={DownloadIcon} />
                </Button>
              </Tooltip>
            </Grid.Item>
          </Grid.Container>
        }
        subHeader={
          (isAdmin || isAuction) && (
            <>
              {selectedView === AUCTION_ELIGIBLE_LOADS && (
                <StartAuctionButton
                  loads={loads}
                  selectedRows={selectedRows}
                  onRefresh={onRefresh}
                />
              )}
              {selectedView === LOADS_IN_AUCTION && (
                <EndAuctionButton
                  loads={loads}
                  selectedRows={selectedRows}
                  onRefresh={onRefresh}
                />
              )}
            </>
          )
        }
        rowSelection={
          selectedView !== AUCTION_HISTORY &&
          (isAdmin || isAuction) &&
          'multiple'
        }
        onRowSelect={onRowSelect}
        selectedRows={selectedRows}
        {...restProps}
      />
    </form>
  )
}

export default LoadBoardTable
